import React, { FunctionComponent } from 'react'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import Nav, { updateNav } from './Nav'
import SEO from './SEO'
import * as blogStyles from './Blog.module.css'

export interface blogProps {
  data: {
    allWpPost: {
      edges: {
        node: {
          slug: string
          content: string
          title: string
          excerpt: string
          featuredImage: {
            node: {
              localFile: {
                childImageSharp: {
                  gatsbyImageData: IGatsbyImageData
                }
              }
            }
          }
        }
      }[]
    }
  }
}

export const Blog: FunctionComponent<blogProps> = (props) => {
  updateNav()
  return (
    <div>
      <header className={blogStyles.blogHeader}>
        <div className={blogStyles.title}>Our European Tour</div>
      </header>
      <div className={blogStyles.contentWrapper}>
        {props.data.allWpPost.edges.map((post) => {
          const content = post.node.excerpt.replace(
            /<p class="link-more.*<\/p>/,
            ''
          )
          let featMedia: JSX.Element
          if (
            post.node.featuredImage.node.localFile === null ||
            post.node.featuredImage.node.localFile === undefined
          ) {
            featMedia = <div />
          } else {
            featMedia = (
              <GatsbyImage
                image={
                  post.node.featuredImage.node.localFile.childImageSharp
                    .gatsbyImageData
                }
                alt="Featured image"
                className={blogStyles.blogImage}
              />
            )
          }
          return (
            <Link
              className={blogStyles.postWrapper}
              key={post.node.slug}
              to={`/blog/${post.node.slug}`}
            >
              <h2
                className={blogStyles.blogTitle}
                dangerouslySetInnerHTML={{ __html: post.node.title }}
              />
              {featMedia}
              <div
                className={blogStyles.excerpt}
                dangerouslySetInnerHTML={{ __html: content }}
              />
              <div className={blogStyles.more}>Read more</div>
            </Link>
          )
        })}
        <Nav />
      </div>
      <SEO
        title="Our European Tour - Blog"
        description="This is the tour blog for John and Tracey Green"
        image="/bertie-beach-small.jpg"
        type="article"
      />
    </div>
  )
}
