import React, { FunctionComponent } from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { Blog, blogProps } from '../components/Blog'
import { blogPostProps } from '../components/BlogPost'

const BlogPage: FunctionComponent<blogProps> = (props: blogProps) => (
  <Layout>
    <Blog data={props.data} />
  </Layout>
)

export const query = graphql`
  {
    allWpPost(sort: { fields: date, order: DESC }) {
      edges {
        node {
          slug
          content
          title
          excerpt
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(height: 300, width: 300, layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPage
